.container {
  max-width: 900px;
  width: 100%;
  padding: 3em;
  padding-top: 5em;
  margin: auto;
}

.leftContainer {
  width: 65%;
  float: left;
}

.leftContainer > div {
  margin-top: 1em;
}

.rightContainer {
  width: 30%;
  float: right;
}

.imageContainer {
  margin-top: 1em;
  height: 15em;
}

@media only screen and (min-width: 1300px) {
  .imageContainer {
    max-height: 20em;
  }
}

@media only screen and (min-width: 1500px) {
  .imageContainer {
    max-height: 25em;
  }
}
