.contentTable {
  font-family: "Barlow", "Helvetica", "Arial", sans-serif;
  margin-bottom: 2em;
  margin-top: 1em;
}

.infoCell {
  width: 70px;
}

.tableRow {
  width: 50px;
}

.edit {
  display: flex;
  padding: 8px;
  align-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
}

.resetButton {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
}

.inputLabel {
  margin-top: 32px;
}
