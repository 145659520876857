.container {
  margin: auto;
  text-align: center;
  background-image: url("../../images/profile-blob.png");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  background-size: contain;
  padding: 3em;
  padding-top: 4em;
}

.successContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 4px;
  background: white;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 6em;
}

.form {
  padding: 16px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}

.form > .formTextField {
  margin-top: 8px;
  margin-bottom: 8px;
}

.form > .buttonContainer {
  float: right;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 20;
}

.paragraph {
  vertical-align: baseline;
  padding: 8px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
  text-align: left;
}
@media screen and (max-height: 700px) {
  .container {
    margin-bottom: 17em;
  }
}
@media screen and (max-width: 430px) {
  .container {
    margin-bottom: 20em;
  }
}
@media screen and (max-width: 400px) {
  .container {
    margin-bottom: 29em;
  }
}
@media screen and (max-width: 600px) {
  html,
  body {
    max-width: 100%;
    overflow-x: hidden;
  }
}
