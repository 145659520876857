.container {
  padding-top: 1em;
}

.appBar * {
  font-size: 0.9em;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  min-height: 100px;
}

.logoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1em;
}

.titleContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.linksContainer {
  display: flex;
  justify-content: flex-end;
}

.link {
  margin-left: 1em;
  margin-right: 0.5em;
}

.link:hover {
  color: #6c6cfc;
}

.linkActive {
  margin-left: 1em;
  margin-right: 0.5em;
  color: #6c6cfc !important;
  text-decoration: underline !important;
  text-underline-offset: 0.6em !important;
  text-decoration-thickness: 0.07em !important;
}

.list {
  max-width: 300px;
  width: 100vw;
}

.subItem {
  font-size: 0.8em;
}

.dropdown {
  transform: translate(-80px, 40px);
  padding: 1em;
  position: fixed;
  max-width: 220px;
  font-size: 0.8em;
  border-radius: 10px;
  background: #6c6cfc;
  cursor: pointer;
}

.dropdownLink:hover {
  color: gold;
}

.btnActive {
  margin-left: 1em;
  margin-right: 0.5em;
  color: #6c6cfc !important;
  text-decoration: underline !important;
  text-underline-offset: 0.6em !important;
  text-decoration-thickness: 0.07em !important;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  line-height: 22px;
  display: inline-flex;
}

.btn {
  margin-left: 1em;
  margin-right: 0.5em;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  line-height: 22px;
  display: inline-flex;
}

.btn:hover {
  color: #6c6cfc !important;
  background-color: transparent;
}

.dropdownDiv {
  display: flex;
}

.imitateLink {
  text-transform: capitalize;
  text-size-adjust: 10em;
  font-size: large;
  border: none;
  vertical-align: "text-bottom";
  margin-top: 0.2em;
}
