.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  position: relative;
}

.form input[type="text"],
.form input[type="email"],
.form input[type="tel"] {
  width: 100%;
}

.formGroupLabel {
  color: var(--primary-color);
  border-bottom: 2px solid var(--border-and-divider-color);
  align-self: flex-start;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 0;
}
.basicInfo {
  margin-top: 0;
}

hr.divider {
  margin-top: 8px;
  margin-bottom: 16px;
}

.successContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 4px;
  background: white;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

form .errorBox {
  background: var(--error-color);
  padding: 16px;
  margin-top: 16px;
  color: white;
}

.inputLabel {
  margin-top: 8px;
}
