.container {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.dateFieldsContainer {
  display: flex;
  margin-top: 1em;
  margin-bottom: 2em;
}

.selectContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
}

div.select {
  min-width: 150px;
  margin-left: 4px;
  margin-right: 4px;
}

div.selectWide {
  min-width: 250px;
  margin-left: 4px;
  margin-right: 8px;
}

div.select:not(:last-child) {
  margin-right: 8px;
}

hr.divider {
  margin-top: 16px;
  margin-bottom: 16px;
}

.removalButton {
  flex: 1;
  float: right;
  align-self: flex-end;
}
