.container {
  max-width: 900px;
  width: 100%;
  padding: 3em;
  padding-top: 5em;
  margin: auto;
  line-height: 1em;
}

.leftContainer {
  width: 65%;
  float: left;
}

.rightContainer {
  width: 35%;
  float: right;
}

.leftContainer > div {
  margin-bottom: 1em;
}

.image {
  max-width: 100%;
  margin-bottom: 3em;
  margin-top: -8em;
  margin-left: 4em;
}

.link {
  margin-top: 1em;
  padding-left: 35%;
  z-index: 5;
}

@media only screen and (min-width: 1300px) {
  .imageContainer {
    max-width: 20em;
  }
}

@media only screen and (min-width: 1500px) {
  .imageContainer {
    max-width: 35em;
  }
}
