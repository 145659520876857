.content {
  min-height: calc(100vh - 150px); /* 150px is ca. the header's plus footer's size */
}
.home {

  min-height: calc(100vh - 60px); /* 100px is the header's size */
  background-image: url("../../images/divider.svg");
  /* Center and scale the image nicely */
  background-position-y: bottom;
  background-origin: inherit ;
  background-position: 0em 22em;
  background-repeat: no-repeat;
  background-size: cover;
}