.container {
  margin: 40px 0;
  width: 100%;
  display: flex;

  justify-content: center;
}

.textContainer {
  margin: 1em;
  line-height: 1em;
  max-width: 1200px;
}
