.mediaContainerHome {
  justify-content: center;
  margin-top: -100px;
}
.mediaContainer {
  justify-content: center;
  position: fixed;
  bottom:0;
}
.mediaList {
  font-family: "Barlow", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mediaListItem {
  font-size: 0.8em;
  color: var(--auxiliary-color);
}
.mediaListItemHome {
  font-size: 0.8em;
  color: white;
}

.image {
  height: 40px;
  max-width: 100%;
}
