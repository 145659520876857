.container {
  max-width: 600px;
  width: 100%;
  padding: 3em;
  padding-top: 4em;
  margin: auto;
  background-image: url("../images/profile-blob.png");
  height: 338px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}

.successContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 6em;
}

.form {
  padding: 16px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}

.form > .buttonContainer {
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 20;
}

.form > .buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
}
.helper {
  margin-top: 2em;
  margin-bottom: 1em;
}
