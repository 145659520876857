.container {
  max-width: 900px;
  width: 100%;
  padding: 3em;
  padding-top: 5em;
  margin: auto;
  line-height: 1em;
}

.leftContainer {
  width: 65%;
  float: left;
}

.leftContainer > div {
  margin-bottom: 1em;
}

.rightContainer {
  width: 30%;
  float: right;
}

.imageContainer {
  margin-top: 1em;
  height: 15em;
}

.stepsContainer {
  padding: 8em;
  padding-top: 2em;
  clear: both;
}

.step {
  clear: both;
}

.stepTable {
  width: 100%;
}

.tableStep td {
  vertical-align: baseline;
}

.stepLeft {
  width: 50%;
  padding: 1em;
}

.stepRight {
  width: 50%;
  padding: 1em;
}

.stepImage {
  height: 13em;
}

@media only screen and (min-width: 1300px) {
  .imageContainer {
    max-height: 20em;
  }
}

@media only screen and (min-width: 1500px) {
  .imageContainer {
    max-height: 25em;
  }
}
