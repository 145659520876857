.tabContainer {
  padding: 16px;
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.container {
  padding-top: 3em;
  max-width: 900px;
}

.appBar * {
  font-size: 0.9em;
  font-family: "Barlow", "Helvetica", "Arial", sans-serif;
  font-size: 1.1em;
  align-self: center;
}

.link {
  margin-left: 1em;
  margin-right: 0.5em;
}

.link:hover {
  color: #6C6CFC;
}

.linkActive {
  margin-left: 1em;
  margin-right: 0.5em;
  color: #6C6CFC !important;
  text-decoration: underline !important;
  text-underline-offset: 0.6em !important;
  text-decoration-thickness: 0.07em !important;
}