.container {
  max-width: 600px;
  width: 100%;
  padding: 3em;
  padding-top: 4em;
  margin: auto;
  background-image: url("../../images/profile-blob.png");
  height: 338px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}

.form {
  padding: 16px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}

.form>.formTextField {
  margin-top: 8px;
  margin-bottom: 8px;
}

.form>.buttonContainer {
  float: right;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 20;
}