.container {
  display: flex;
  flex-direction: column;
}

.container > div {
  margin-bottom: 16px;
}

.chip {
  margin: 4px;
}

.bold {
  font-weight: bold;
}

.contentTable {
  font-family: "Barlow", "Helvetica", "Arial", sans-serif;
  margin-bottom: 2em;
}
